import React from 'react';
import './Label.scss';

const Label = ({ label, link }) => {
    const onClick = () => {
        if (link) {
            window.open(link, '_blank');
        }
    }
  return (
    
    <div className={`label ${link ? 'label-link' : ''}`} onClick={onClick}>
      {label}
    </div>
  );
}

export default Label;