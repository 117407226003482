import React from 'react';
// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './IconWithLabel.scss';

const IconWithLabel = ({ icon, label }) => {

    return (
        <div className="icon-item" >
            <div className="icon-item__icon">
               {/* <FontAwesomeIcon icon={fas["faCheckSquare"]} size='2x' color='#178754' /> */}
                <img src={'https://www.ok-ucetnictvi.cz/static/img/check.png'} alt="OK Účetnictví" width={'24px'} />
            </div>
            <div className="icon-item__label">
                {label}
            </div>
        </div>
    );
}

export default IconWithLabel;