import React from "react";
import ReactDOM from "react-dom/client";
// import Button from "./components/Button";
import Header from "./components/Header";
import Content from "./components/Content";
import Image from "./components/Image";
import Section from "./components/Section";
import Article from "./components/Article";
// import Divider from "./components/Divider";
import IconWithLabel from "./components/IconWithLabel";
// import SectionRounded from "./components/SectionRounded";
import Label from "./components/Label";
import Copyright from "./components/Copyright";
import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";
import Hero from "./components/Hero";
// import { faCircleDown } from "@fortawesome/free-solid-svg-icons";

const App = () => {
  return (
    <div>
      <Header />
     
      <Content>
      <Hero />
        {/* <Section id={"promotext"} promotext>
          <h4>
            Poskytujeme komplexní servis v oblasti zpracování mezd, účetnictví,
            ekonomického i podnikatelského poradenství.
          </h4>
        </Section>
 */}
        <Section id={"o-nas"}>
          {/* Sekce o nás */}
          <Article text no_distance>
            <h1 style={{ MarginLeft : '1rem' }}> O firmě</h1>
            <p>
            Společnost OK Účetnictví působí na trhu od roku 2011.
              <br />
              <br />
              Díky několikaleté praxi v oboru a bohatým zkušenostem vám dokážeme zajistit komplexní servis v oblasti zpracování mezd, účetnictví, ekonomického i podnikatelského poradenství. 
              <br />
              <br />
              Mezi naše klienty patří především malé a střední společnosti, kterým nabízíme ekonomické služby zahrnující zpracování mzdové agendy a účetnictví. Dále poskytujeme daňové, mzdové a ekonomické poradenství. Naším cílem je nabídka externího zpracování mezd a účetnictví s plným komfortem interní mzdové či finanční účtárny. Chceme šetřit váš čas, proto je samozřejmostí  elektronické předávání dokladů, automatizace a digitalizace.
              <br />
              <br />
              Nabízíme komplexní služby, které se přizpůsobí vašemu individuálnímu podnikání.
              <br />
              <br />
              Naše společnost je z titulu odpovědnosti pojištěna. 

            </p>
          </Article>
          <Article image>
            <Image
              src="https://ok-ucetnictvi.cz/static/img/o-firme.webp"
              alt="image"
            />
          </Article>{" "}
        </Section>
        <Section id={"promotext"} promotext>
         
            <h3>Přesvědčte se o tom, proč je dobré spolupracovat s námi. Na
            Vašem úspěchu nám záleží.
         </h3>
        </Section>
        {/* <Divider /> */}
        <Section id={"ucetnictvi"}>
          {/* Sekce o nás */}
          <Article right text>
            <h1> Účetnictví</h1>
            <IconWithLabel
              icon="check"
              label="Vedení podvojného účetnictví a daňové evidence"
            />
            <IconWithLabel
              icon="check"
              label="Zpracování daňových přiznání právnických a fyzických osob"
            />
            <IconWithLabel
              icon="check"
              label="Roční nebo pravidelné účetní závěrky"
            />
            <IconWithLabel
              icon="check"
              label="Zastoupení klienta v případě kontrol od úřadů"
            />
            {/* <Button label="Mám zájem" bottom link="#kontakt" accent icon={faCircleDown}/> */}
          </Article>
          <Article right text>
            <h1> Mzdy</h1>
            <IconWithLabel
              icon="check"
              label="Kompletní vedení mzdové agendy "
            />
            <IconWithLabel
              icon="check"
              label="Kompletní vedení potřebné personální evidence"
            />
            <IconWithLabel
              icon="check"
              label="Vyúčtování srážkové daně a daně ze závislé činnosti"
            />
            <IconWithLabel
              icon="check"
              label="Zastoupení klienta v případě kontrol od úřadů"
            />
            {/* <Button label="Mám zájem" bottom link="#kontakt" accent icon={faCircleDown} /> */}
          </Article>
        </Section>
               <Section id={"dane"}>
          {/* Sekce o nás */}
          <Article right text>
            <h1> Daně</h1>
            <IconWithLabel
              icon="check"
              label="Zpracování daňového přiznání k dani z přidané hodnoty"
            />
            <IconWithLabel
              icon="check"
              label="Zpracování daňového přiznání k dani z příjmů právnických a fyzických osob"
            />
            <IconWithLabel icon="check" label="Zpracování daně silniční" />
            <IconWithLabel
              icon="check"
              label="Zpracovaní daně z nemovitosti a jiné"
            />
            <IconWithLabel
              icon="check"
              label="Hlídání daňových termínů a daňových povinností"
            />
            {/* <Button label="Mám zájem" bottom link="#kontakt" accent icon={faCircleDown} /> */}
          </Article>
          <Article right text>
          <h1> Poradenství</h1>
          <p>
            Poskytujeme poradenství v oblastech účetnictví, daní, mezd a
            personalistiky pro malé a střední společnosti. Jsme kdykoliv k
            dispozici Vám poradit v případě jakýchkoliv dotazů.
          </p>
          {/* <Button
            label="Mám zájem"
            bottom
            link="#kontakt"
            accent
            icon={faCircleDown}
          /> */}
           </Article>
       

        </Section>
        <div style={{ height: "3rem" }}></div>
        <Section id={"kontakt"}>
          <Article contact>
            <h3>Kontaktní údaje</h3>
            <Label label="OK Účetnictví" />
            <Label label="Tel.: +420 725 078 278" />
            <Label
              label="info@ok-ucetnictvi.cz"
              link={"mailto:info@ok-ucetnictvi.cz"}
            />
            <Label label="Datová schránka: w5be3qz" />
          </Article>
          <Article contact>
            <h3>Fakturační údaje</h3>
            <Label label="IČ: 74507541" />
            <Label label="Ing. Martin Kašpar" />
            <Label label="Křižíkova 393/73" />
            <Label label="Praha 8 Karlín, 186 00" />
          </Article>
        </Section>
        <Copyright />
      </Content>
      <div></div>
      <CookieBanner
        privacyPolicyLinkText="Zobrazit podrobnosti o používání cookies..."
        necessaryOptionText="Povolit pouze nezbytné cookies"
        preferencesOptionText="Povolit všechny cookies"
        statisticsOptionText="Povolit cookies pro statistiky"
        marketingOptionText="Povolit cookies pro marketing"
        acceptAllButtonText="Povolit všechny cookies"
        message="Tento web používá cookies k poskytování služeb, personalizaci reklam a analýze návštěvnosti. Používáním tohoto webu s tím souhlasíte."
        styles={{
          dialog: {
            backgroundColor: "#eee",
            height: "auto",
            width: "calc(100% - 48px)",
            borderRadius: "16px",
            padding: "16px",
          },
          optionLabel: { color: "#222", fontSize: "12px", lineHeight: "12px" },
          checkbox: { marginRight: "8px" },
          button: {
            backgroundColor: "#1A237E",
            color: "#fff",
            borderRadius: "16px",

            border: "none",
          },
        }}
        onAcceptPreferences={() => {
          // load your preferences trackers here
        }}
        onAcceptStatistics={() => {
          // load your statistics trackers here
        }}
        onAcceptMarketing={() => {
          // load your marketing trackers here
        }}
        onAcceptAll={() => {
          // load all your trackers here
        }}
      />
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
