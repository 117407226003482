import React, { useState } from "react";
import HeaderButton from "./Headerbutton";
import "./Header.scss";
import Button from "./Button";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Header = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="header-mobile">
        <div className="header__logo">
          <a href="https://www.ok-ucetnictvi.cz/">
          <img
            src="https://www.ok-ucetnictvi.cz/static/img/ok-ucetnictvi-logo_modre.webp"
            alt="OK Účetnictví"
            width={"100%"}
          />
          </a>
        </div>
        <div className="header-mobile__menu">
          <button className="toggleButton" onClick={toggleMenu}>
            Menu
          </button>
          {isOpen && (
            <div className="header-mobile__menu__content">
              <ul style={{ width: "100" }}>
              <li style={{ lineHeight: "50px", listStyle: "none" }}>
                  <HeaderButton
                    label="O firmě"
                    link="#o-nas"
                    onClick={toggleMenu}
                  />
                </li>

                <li style={{ lineHeight: "50px", listStyle: "none" }}>
                  <HeaderButton
                    label="Naše služby"
                    link="#ucetnictvi"
                    onClick={toggleMenu}
                  />
                </li>
                <li style={{ lineHeight: "50px", listStyle: "none" }}>
                  <HeaderButton
                    label="Kontakt"
                    link="#kontakt"
                    onClick={toggleMenu}
                  />
                </li>
              </ul>
              <Button
                className="header-mobile__menu__content__close"
                onClick={toggleMenu}
                icon={faTimes}
                label={"Zavřít"}
              />
            </div>
          )}
        </div>
      </div>

      <nav className="header">
        <div className="header__logo">
          <img
            src="https://www.ok-ucetnictvi.cz/static/img/ok-ucetnictvi-logo_modre.webp"
            alt="OK Účetnictví"
            width={"100%"}
          />
        </div>
        <div className="topNavbarResponsive">
        <HeaderButton label="O firmě" link="#o-nas" />
        <HeaderButton label="Naše služby" link="#ucetnictvi" />
          <HeaderButton label="Kontakt" link="#kontakt" />
        </div>
      </nav>
    </div>
  );
};

export { Header as default };
