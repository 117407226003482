import React from "react";
import './Headerbutton.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderButton = ({ label, accent, link, icon, onClick }) => {

    
    return (React.createElement("a", { className: `headerButton ${accent ? 'headerButton__accent' : ''}`, href: link, label, onClick: onClick }, label, icon ? <FontAwesomeIcon icon={icon} style={{ marginLeft : '12px'}} /> : ''));
};

export { HeaderButton as default };