import React from "react";
import './Hero.scss';
import Button from "./Button";
import HeaderButton from "./Headerbutton";
import { faCircleDown, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";



const Hero = () => {
    return (
        <div className="hero" id={"hero"}>
          <div className="hero__title">Komplexní služby vedení účetnictví</div>
          <div className="hero__subtitle">
          Poskytujeme komplexní servis v oblasti zpracování mezd, účetnictví,
            ekonomického i podnikatelského poradenství.
          </div>
          <div className="hero__buttons">
            <Button label="Zjistit více" link="#o-nas" icon={faCircleDown} />
            <HeaderButton label="Kontaktovat" link="#kontakt" accent icon={faPhoneVolume} />
          </div>
          {/* <div className="icon-scroll"> </div> */}
        </div>
    );
}

export default Hero;