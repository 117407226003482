import React from "react";
import './Article.scss';

const Article = ({ children, right, contact, image, text, review, no_distance }) => {
    return (
        <div className={`article ${text ? 'article-text' : ""} ${right ? 'article-right' : ""} ${contact ? 'article-contact' : ""} ${image ? 'article-image' : ""} ${review ? 'article-review' : ""} ${no_distance ? 'article-text__nodistance' : ""}`}>
            {children}
        </div>
    );
}

export default Article;