import React from "react";
import "./Copyright.scss";



const Copyright = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="copyright">
        &copy; {currentYear}. OK Účetnictví. Všechna práva vyhrazena. Web vytvořil a spravuje jankorous.cz. 
        </div>
    );
    }

export default Copyright;