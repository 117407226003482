import React from "react";
import './Section.scss';

const Section = ({ id, children, promotext, reviews }) => {
    return (
        <div className={`section ${promotext ? "section-promotext" : ""} ${reviews ? "section-reviews" : ""}`} id={id} >
            {children}
        </div>
    );
}

export default Section;